import React from 'react'

function Home() {
    return (
        <div className='home-container'>
            <img className='w-100 opacity-50' height={600} src={require('../assets/img/home/banner-1.jpg')} />
            <div className='position-absolute top-50 start-50 translate-middle'><h3 className='text-center home-description'>Our smart and expert team supports you with their creative and
                innovative solutions.</h3>
                <h3 className='text-center text-white mt-4'>Do not hesitate to search our services which we became
                    digital solution partners. We do not serve Turkish citizens.</h3>
                <div className='d-flex justify-content-center mt-4'>
                    <button type="button" class="btn btn-outline-light btn-md">LEARN MORE</button>

                </div>
            </div>
            <div className='d-flex justify-content-center'><i class="fa-solid fa-chevron-down "></i></div>
        </div >
    )
}

export default Home