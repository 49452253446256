import React from 'react'
import background from '../assets/img/services/cardsImages/software-bg.jpg'


function Software() {
  return (
    <div className='home-container' style={{ backgroundImage: `url(${background})` }}>
      <div className='services-banner row'>
        <div className='col-md-5 offset-md-2'>
          <h2 className='software-title pt-4'>SERVICES</h2>
          <h5 className='services-description'>Crm Software and Support</h5>
          <p className='software-description'>Connect with your customers wherever they are across every channel: telephone, email, live chat, and social media wherever they are.</p>

          <h5 className='services-description'>Live Chat Software and Support</h5>
          <p className='software-description'>Live chat and messaging create a personal connection with customers looking for support</p>

          <h5 className='services-description'>Office 365 Management</h5>
          <p className='software-description'>A cloud-based subscription service that brings together the best tools for the way people work today.</p>

          <h5 className='services-description'>Voip Services</h5>
          <p className='software-description'>Business-oriented solutions for global communications coverage..</p>

          <h5 className='services-description'>MT4 / MT5 Management</h5>
          <p className='software-description'>A cost effective solution for metadata management for both structured and unstructured data.</p>

          <h5 className='services-description'>Domain Management</h5>
          <p className='software-description'>Keeping domain (or domains) stable, secure, and able to support related websites.</p>
          
          <h5 className='services-description'>VPN Management</h5>
          <p className='software-description'>Securely and efficiently manage your remote access VPN network from a single point of administration, even as your organization grows and the number of users and/or endpoint devices increases.</p>

          <h5 className='services-description'>Hosting Management</h5>
          <p className='software-description'>Web hosting management by providing many advanced features and modern panel with full control.</p>

          <h5 className='services-description'>Storage Management</h5>
          <p className='software-description'>Set of processes used by an administrator to keep your data and storage equipment’s safe.</p>

        </div>
        <div className='col-md-3 offset-md-1'>
          <img width={300} style={{marginTop: 120, borderRadius: 20}} src={require(`../assets/img/services/cardsImages/software-bg.jpg`)} />
        </div>

      </div>


      <div className='d-flex justify-content-center mt-4'><i class="fa-solid fa-chevron-down "></i></div>
    </div >
  )
}

export default Software