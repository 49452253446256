import React, { useState, useEffect } from "react";
import styled from "styled-components";
import background from '../assets/img/contact/map.png'

// Assets


import connector from "../helpers/connector"
import InputMask from 'react-input-mask';
import { NotificationContainer, NotificationManager } from 'react-notifications';

export default function Contact() {
  var formUrl = window.location.protocol + '//' + window.location.hostname + "/api/v1/Form/Submit";

  const [fullname, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [validator, setValidator] = useState(false);
  const [isNull, setIsNull] = useState(false);
  const [isSend, setIsSend] = useState(false);

  var data = {
    domain: window.location.protocol + '//' + window.location.hostname,
    formSystemName: "ContactForm",
    formValues: {
      "fullName": fullname,
      "email": email,
      "phone": String(phone).replace(" ", "").replace("(", "").replace(")", "").replace(" ", "").replace(" ", ""),
      "country": "Podgorica",
      "countryCode": "+382",
      "approvedConditions": true,
      "message": message
    },
    languageIsoCode: 'en',
    siteSystemName: 'IDOLSoftware',

  }

  useEffect(() => {
    if (fullname === "" || email === "" || phone === "" || message === "" || phone.includes("_") || validator === false) {
      setIsNull(false)
    }
    else {
      setIsNull(true)
    }
  }, [fullname, email, phone, message, validator])

  function EmailChange(e) {
    const validEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    let isOk = validEmail.test(String(e).toLowerCase())
    if (isOk) {
      setEmail(e);
      setValidator(true)
    }
    else {
      setEmail(e);
      setValidator(false)
    }
  }

  async function Submit() {
    setIsSend(true)
    connector(formUrl, data)
      .then(data => {
        if (data.status === 1) {
          NotificationManager.success('We will not return as soon as possible.', 'Your Mail Request Has Been Received!');
          setIsSend(false)
        }
        else {
          NotificationManager.error('Please refresh the page and try again.', 'Something went wrong!');
          setIsSend(false)
        }
      })
      .catch(err => {
        if (err.response) {
          if (err.response.status === 404) {
            NotificationManager.error('Please refresh the page and try again.', 'Something went wrong!');
            setIsSend(false)
          }
          else {
            NotificationManager.success('We will not return as soon as possible.', 'Your Mail Request Has Been Received!');
            setIsSend(false)
          }
        }
        else {
          NotificationManager.error('Please refresh the page and try again.', 'Something went wrong!');
          setIsSend(false)
        }
      })
    setFullName("");
    setEmail("");
    setPhone("");
    setMessage("");
  }

  return (
    <div style={{ backgroundImage: `url(${background})` }}>
    <Wrapper id="contact" >
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Contact Us!</h1>
            <p className="font15">
              To get information about our services, it is enough to fill out the form…
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-11 col-sm-11 col-md-6 col-lg-6">
              <Form>
                <label className="font13">Name &amp; Surname:</label>
                <input type="text" id="fname" name="fname" className="font20 extraBold" value={fullname} onChange={event => { setFullName(event.target.value) }} />
                <label className="font13">Email:</label>
                <input type="text" id="email" name="email" className="font20 extraBold" value={email} onChange={event => { EmailChange(event.target.value) }} />
                <label className="font13">Phone Number:</label>
                <InputMask mask="(999) 999 99 99" type="phone" id="phone" name="phone" className="font20 extraBold" value={phone} onChange={event => { setPhone(event.target.value) }} />
                <label className="font13">Message:</label>
                <textarea rows="4" cols="50" type="text" id="message" name="message" className="font20 extraBold" value={message} onChange={event => { setMessage(event.target.value) }} />
              </Form>
              <SumbitWrapper className="flex">
                {isNull ?
                  <ButtonInput onClick={() => { Submit() }} className="pointer animate radius8" style={{ maxWidth: "220px", textAlign: "center" }} >SEND</ButtonInput>
                  :
                  <ButtonDisableInput className="pointer animate radius8" style={{ maxWidth: "220px", textAlign: "center"}} >
                    {isSend ? "Sending..." : "SEND"}
                  </ButtonDisableInput>}
              </SumbitWrapper>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </Wrapper>
    </div>
  );
}

const Wrapper = styled.section`
  width: 100%;
  margin-bottom: 100px
`;
const HeaderInfo = styled.div`
  padding: 70px 0 0 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.button`
  border: 1px solid #f57314;
  background-color: #f57314;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #f57314;
    border: 1px solid #f57314;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ButtonDisableInput = styled.button`
  border: 1px solid #999;
  background-color: #999;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;









