import './App.css';
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import PrivacyAndSecurity from './pages/PrivacyAndSecurity';
import Header from './components/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './pages/Home';
import Footer from './components/Footer';
import Services from './pages/Services';
import Software from './pages/Software';
import DigitalMarketing from './pages/DigitalMarketing'
import DataAnalysis from './pages/DataAnalysis';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Address from './pages/Address';
import NotFound from './pages/NotFound';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route index element={<Home />} />
        <Route path="privacy-and-security-policy" element={<PrivacyAndSecurity />} />
        <Route path="services" element={<Services />} />
        <Route path="software" element={<Software />} />
        <Route path="digital-marketing" element={<DigitalMarketing />} />
        <Route path="data-analysis" element={<DataAnalysis />} />
        <Route path="who-are-we" element={<AboutUs />} />
        <Route path="contact" element={<Contact />} />
        <Route path="address" element={<Address />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
