import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div className='row footer-container ms-2'>
      <div className='col-md-2 offset-md-2'>
        <img width={150} src={require('../assets/img/logo/uk-web.png')} />
        <div>
           <h6> <i class="fa-solid fa-location-dot me-2 mt-4"></i>Address</h6>
          <p className='footer-content'>Omladinskih Brigada BR.12</p>
          <p className='footer-content  footer-city'>Podgorica</p>
        </div>
        <div>
          <h6><i class="fa-solid fa-envelope me-2"></i>Mail</h6>
          <p  className='footer-content'>support@idol-soft.com</p>
        </div>
      </div>
      <div className='col-md-7 row'>
        <div className='col-md-3'>
          <h5 className='footer-title'>SERVICES</h5>
          <hr className='col-md-6'/>
          <Link className='footer-root' to='software'>SOFTWARE</Link>
          <Link className='footer-root' to='digital-marketing'>DIGITAL MARKETING</Link>
          <Link className='footer-root' to='data-analysis'>DATA ANALAYSING</Link>
        </div>
        <div className='col-md-3'>
          <h5 className='footer-title'>ABOUT US</h5>
          <hr className='col-md-6'/>
          <Link className='footer-root' to='who-are-we'>WHO ARE WE</Link>
          <Link className='footer-root w-75' to='privacy-and-security-policy'>PRIVACY AND SECURITY POLICY</Link>
        </div>
        <div className='col-md-3'>
          <h5 className='footer-title'>CONTACT</h5>
          <hr className='col-md-6'/>
          <Link className='footer-root' to='contact'>CONTACT FORM</Link>
          <Link className='footer-root' to='address'>ADDRESS</Link>
        </div>
      </div>
      <div>
        <p className='text-center'>Idol Software 2022 ©</p>
      </div>
    </div>
  )
}

export default Footer