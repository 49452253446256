import React from 'react'

function PrivacyAndSecurity() {
  return (
    <div style={{ margin: "80px" }}>
            <p>IdolSoftware (‘the Company’) is committed to protect the privacy and confidentiality of personal data obtained from Client, in compliance with applicable legal requirements. The Company shall collect data only to the extent that it is necessary for the business relationship with the Client.</p>
            <p>This Privacy and Security Policy explains how the Company collects, maintains, uses and transmits personal data about the Client, including data collected during the Client’s visits to our websites.</p>
            <h4 style={{ margin: "2px" }}>Collection and Use of Client’s Personal Data</h4>
            <p>Client is required to provide to the Company with personal data necessary for the establishment of the business relationship, the provision of services and access to trading account/s and activity. Data provided during the registration process might include inter alia; Client’s date of birth, full name and surname, full residential and/or business address, information available on client’s identification documents (i.e. Passport / Identification Card), contact information (i.e. mobile phone number, e-mail address). This information enables the Company to identify and verify the Client, to activate and provide the Client with a trading account and access code/password and to contact the Client and provide information on available services, products and promotions.</p>
            <p>The Company also obtain information from the Client’s use of its websites, dedicated interfaces or communication channels, trading software or platforms provided. This information may include site areas visited, pages viewed, frequency and duration of visits, Internet Protocol (IP) address, computer identification parameters, types of transactions conducted, documents downloaded, mouse clicks, mouse movements and scrolling activity.</p>
            <p>The Company collects anonymous statistical information in order to ensure that it meets the needs of its clients. By collecting such data, the Company is able to monitor and improve the services offered to existing and potential clients. The Company may share such information with third parties.</p>
            <h4 style={{ margin: "2px" }}>Updating Client’s information</h4>
            <p>During the Client’s business relationship with the Company, Client might be requested to provide personal data necessary for the maintenance of such relationship, including any renewed identification document, updated contact information.</p>
            <p>Additionally, the Client may inform the Company for any change on personal details. The Company shall change the Client’s personal information in accordance with the Client’s instructions and delete previously obtained information except to the extent that the Company is required to hold the relevant personal data for regulatory or legal purposes and/or to provide the Client with the services requested or to retain business records</p>
            <h4 style={{ margin: "2px" }}>Transfer of Data to Third Parties</h4>
            <p>Personal data may be transferred by the Company to third parties located in European Economic Area as well as to non-European Economic Area only for the purposes described in this Policy.</p>
            <p>Third parties might include Accountants, Auditors, Legal Consultants, Banks and other Credit Institutions, Payment Systems and Payment Service Providers, Marketing and Customer Support Agencies, Service Providers with whom the Company maintains a contractual relationship and entities within the same group of Companies as the Company.</p>
            <p>For the purposes of identification and verification of Client’s identity, the Company shall transmit Client’s data to a third-party service provider authorised to perform such identification, in compliance with relevant regulatory requirements. The Company shall ensure privacy and confidentiality of transmitted data.</p>
            <p>The Company reserves the right to transfer Client’s personal data to third parties if instructed by any regulatory authority, public agency, financial intelligence unit, police, governmental office, tax authority, law enforcement bodies, or if required by law, or in case the Company considers that sharing information is necessary to prevent imminent physical harm or damage to property and/ or persons.</p>
            <p>Transfer of data to entities within the same group of Companies as the Company shall be limited and only if necessary, for the provision of services to Client.</p>
            <p>By proceeding with the establishment of a business relationship, the Client gives express and unambiguous consent to such transfer/s.</p>
            <h4 style={{ margin: "2px" }}>Cookies</h4>
            <p>Cookies are small files of information, which often include a unique identification number or value, which are stored on computer’s hard drive as a result of the Client’s use of the Company’s website/s and/ or trading software. The Company uses cookies for effective operation and maintenance of its website.</p>
            <p>The Company might use both session ID cookies and persistent cookies. A session ID cookie expires when the Client close the browser. A persistent cookie remains on the Client’s hard drive for an extended period of time. Persistent cookies enable the Company to track and target the location and the interests of website’s visitors and to enhance their experience from visiting the Company’s website/s. Third parties that might have access to Company’s website/s for technological support, might use their own cookies.</p>
            <h4 style={{ margin: "2px" }}>Security</h4>
            <p>The Company makes efforts to maintain the appropriate safeguards in order to ensure that the security, integrity and privacy of the data and personal information that the Client have provided is not misused. While such systems and procedures reduce the risk of security breaches, they do not provide absolute security. Therefore, the Company cannot guarantee that the services will be immune from any wrongdoings, malfunctions, unlawful interceptions or unauthorized access to the information stored therein and to other information security risks, or that Client’s private communications on or through the Company’s website/s (including communication interfaces) will remain private.</p>
            <h4 style={{ margin: "2px" }}>Updating Client’s information</h4>
            <p>The Client may inform the Company for any change on personal details. The Company shall change the Client’s personal information in accordance with the Client’s instructions and delete previously obtained information except to the extent that the Company is required to hold the relevant personal data for regulatory or legal purposes and/or to provide the Client with the services requested or to retain business records.</p>
            <h4 style={{ margin: "2px" }}>Links</h4>
            <p>When the Client uses the Company’s website/s, he/she may be able to link to other websites. This Privacy & Security Policy does not apply to those other sites. The Company is not responsible for any personal information collected by third parties via those other sites.</p>
            <h4 style={{ margin: "2px" }}>Enforcement and Amendments</h4>
            <p>The Client by proceeding with registration process agrees and consents to all the provisions stated in this Policy including collection, retention, and use of personal data and transmission to third parties.</p>
            <p>The Company may change and update the terms of this Policy from time to time. The most recent version shall be available on the Company’s website. Client is advised to visit the website frequently in order to acquaint himself/herself with the updated information in regard to personal data.</p>
            <p>If the Client has any questions or concerns regarding this Privacy and Security Policy and the enforcement thereof, he/she can refer them to: support@idol-soft.com</p>
        </div>
  )
}

export default PrivacyAndSecurity