import React from 'react'
import background from '../assets/img/services/cardsImages/marketing.jpg'

function DigitalMarketing() {
  return (
    <div className='home-container' style={{ backgroundImage: `url(${background})` }}>
      <div className='services-banner row'>
        <div className='col-md-5 offset-md-2'>
          <h2 className='software-title pt-4'>DIGITAL MARKETING</h2>
          <h5 className='services-description'>Digital Media Planing</h5>
          <p className='software-description'>Media is a mass concept that contains many communication tools and sends the message it wants to reach to the target. The media also carries out works with advertisements on the way to reaching the target audience. The media also carriesout works that contain advertisements in order to reach the target audience.</p>
          <h5 className='services-description'>Display Ads</h5>
          <p className='software-description'>It also includes applications known as display advertising (banner). Websites or blogs appear in the form of banner areas. This advertisement method is an indispensable form of marketing an awareness. The advantages of display adsare that they;</p>
          <p className='software-description' style={{marginLeft: 10}}>Increase brand awareness and increase sales.</p>
          <p className='software-description' style={{marginLeft: 10}}>Lead to high returns due to selection of the right channel.</p>
          <p className='software-description' style={{marginLeft: 10}}>Result in increased sales with visual and sales-oriented display types.</p>
          <p className='software-description' style={{marginLeft: 10}}>Have potential to achieve high marketing return on investment.</p>
          <p className='software-description' style={{marginLeft: 10}}>Allow you to create personalised ads to potential target customer segments.</p>

          <h5 className='services-description'>Video Ads</h5>
          <p className='software-description'>It is a type of ad that is published on online platforms and directly on video sharing sites. They are published at a certain time at the beginning, in the middle and at the end of videos and during the video streams.</p>
          <p className='software-description'>We providetargeted, measurable and creative access to TV-like content through online video ads, which are one of the world's fastest growing digital ad formats. Video ads also offer users various benefits. Online videos with creative andinformative content are preferred by users as a kind of content that provides the opportunity to know the product and brand better. This indicates that video ads also help to establish a connection between the consumer and thebrand by providing understanding and awareness to the users, with the help of auxiliary and informative content outside of the traditional sales channel.</p>

          <h5 className='services-description'>Native Content/Advertorial Ads</h5>
          <p className='software-description'>With natural advertisements that comply with the design, content and usage habits of the website, we reach the user and increase the brand loyalty without interupting their intended action.</p>
          <p className='software-description'>The rising trend of the marketing sector is Native Advertising. In native advertising, brand messages are placed in video, advertisement or articles in different mediums. Especially with higher potential of viral distributionin online media, these ads enable consumers to meet and interact with the brand in a more natural way while consuming the content they are interested in.</p>

          <h5 className='services-description'>Programmatic Publications</h5>
          <p className='software-description'>We allow you to reach your users with behavioural segmentations in a targeted and secure manner.</p>
          <p className='software-description'>At the end point of the programmatic, digital advertising technologies, it is the advertising technology that allows its users to analyse their behaviour and show them the designated ads by classifying them accordingly.Programmatic purchasing allows advertisers to display their ads within 50 milliseconds through pre-made cookies. The advertiser's predetermined criteria may be based on behavioural and demographic metrics.</p>

          <h5 className='services-description'>Mobile Ads</h5>
          <p className='software-description'>In mobile media, which is the most rapidly growing medium, we reach the most relevant users for your brand by targeting on criteria such as location, device, age and area of interest.</p>
          <p className='software-description'>The purpose of mobile advertising is to reach the right people at the right time.</p>
          
          <h5 className='services-description'>E-mailing</h5>
          <p className='software-description'>E-Mail Marketing is one of the marketing methods that provides the easiest access and returning to the desired audience and is one of the most frequently used methods of digital marketing.</p>
          <p className='software-description'>E-mail marketing enables the brand to send e-mails in the form of promotional, information and celebration messages in order to reach a specific audience. Bulk mail sending can be very effective marketing methods whilealso being quite cost-efficient</p>


        </div>
        <div className='col-md-3 offset-md-1'>
          <img width={300} style={{marginTop: 120, borderRadius: 20}} src={require(`../assets/img/services/cardsImages/marketing.jpg`)} />
        </div>

      </div>


      <div className='d-flex justify-content-center mt-4'><i class="fa-solid fa-chevron-down "></i></div>
    </div >
  )
}

export default DigitalMarketing