import React from 'react'
import background from '../assets/img/services/cardsImages/data_analysis.jpg'


function DataAnalysis() {
  return (
    <div className='home-container' style={{ backgroundImage: `url(${background})` }}>
      <div className='services-banner row'>
        <div className='col-md-5 offset-md-2'>
          <p className='software-description'>Today, the volume of data generated through the internet has reached an enormous size. More than 90% of the information recorded throughout history has been created within the past two years. We now have a brand new playground: Big Data.</p>

          <p className='software-description'>Through the data volume created, your website and mobile app not only allows you to talk with your users, but also allows them to talk with you. Translation of this language spoken in the data language is a very sensitive and painstaking matter.</p>

          <p className='software-description'>Our team specialized in data analysis supports you in tracking the footsteps of the visitors on your web and mobile properties, reporting them in line with your needs, analyzing these according to your goals, and optimizing your services & your user experience with the guidance of these analyzes.</p>

          <h5 className='services-description'>Tracking</h5>
          <p className='software-description'>Helps you to select and integrate Web Analytics tools that will satisfy your needs the most accurately.</p>

          <p className='software-description'>It is the first, but the biggest step that you need to take in order to collect the behavioral data the visitors create on your websites and applications, to monitor the watching and after-click data of all of the ad formats on the internet, to merge the information collected in your CRM and ERP databases with the data created on the internet, listen to your customers, and evaluate your investments.</p>

          <p className='software-description'>Acting together with your technical and operations teams by supporting you throughout the integration process, our ukweb team has extensive experience on different products. Our consultants offer this experience with a result oriented business approach, and ensure that you are informed about even the smallest action of your visitors.</p>
          

        </div>
        <div className='col-md-3 offset-md-1'>
          <img width={300} style={{marginTop: 120, borderRadius: 20}} src={require(`../assets/img/services/cardsImages/data_analysis.jpg`)} />
        </div>

      </div>


      <div className='d-flex justify-content-center mt-4'><i class="fa-solid fa-chevron-down "></i></div>
    </div >
  )
}

export default DataAnalysis