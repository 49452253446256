import React from 'react'
import background from '../assets/img/services/cardsImages/software-bg.jpg'


function AboutUs() {
  return (
    <div className='home-container' style={{ backgroundImage: `url(${background})` }}>
      <div className='services-banner row'>
        <div className='col-md-5 offset-md-2'>
          <h2 className='software-title pt-4'>Who Are We?</h2>
         
          <p className='software-description'>IdolSoftware is dedicated to delivering complete IT solutions to its clients, whether they are start-up operations or large corporations. Utilizing the Tripartite Outsourcing Model (TOM), IdolSoftware unique approach to IT solutions combines the best practices and capabilities.</p>
          <p className='software-description'>This is far from easy to achieve, due to an aversion to change, difficulties in bringing together technological skills with increasingly scarce and expensive software skills, and for lack of strategic perception.

Often, following is not enough. The former benefits from an advantageous position because it is the first, which is difficult (but not impossible) to overcome.

The key is to learn how to merge business with information technology in a way that is truly transformative.

The software will not remain unscathed to this change. The software has to change radically. It is not the old software (the typical financial management package, in particular) that will support digital transformation. But flexible and powerful software production technologies that automate the application of human knowledge, such as Quidgest’s Genio, which have the capacity to promote and dynamize digital transformation.

Digital transformation requires innovation, it is the current way of designating innovation and applying all the benefits (and risks) of innovation and creativity. Digital transformation is the innovation supported by the technological revolution of our time.</p>
          <p className='software-description'>We have extensive expertise in custom software development for various industries, including Biomedical, Manufacturing, Retail, and Cybersecurity. Our
goal is to help entrepreneurs digitalize core processes.
We believe that scaling your uniqueness is what rises value, so we bring the skills to make it happen. Whether you have a powerhouse business or just an
idea on a .ppt file, we can help you develop it. We have a team of experienced developers who can create a custom software solution for your business.

Business automation and digitalization increase your speed and reach lower costs. But digitizing your core processes is what scales your unique strengths
and the outstanding value you bring to clients.</p>

        </div>

      </div>


      <div className='d-flex justify-content-center mt-4'><i class="fa-solid fa-chevron-down "></i></div>
    </div >
  )
}

export default AboutUs