import React from 'react'
import background from '../assets/img/services/banner.png'
import { Link } from 'react-router-dom'

function Services() {
    return (
        <div className='home-container' style={{ backgroundImage: `url(${background})` }}>
            <div className='services-banner'>
                <h1 className='text-center home-description py-4'>SERVICES</h1>
                <h3 className='text-center services-description px-4'>The precise combination of Idol Software’s specific experience and expertise, our passion for our projects and the full array of services we offer makes us unique in the industry, and the ideal candidate for your company’s digital partner. We provide a comprehensive range of services that are a necessity for any business looking to prosper in the digital age.</h3>
                <div className='service-content row'>
                    <div className="card text-white services-cards col-md-3">
                        <Link to='/software'>
                            <img src={require('../assets/img/services/cardsImages/software-bg.jpg')} className="card-img" alt="Software" />
                            <div className="card-img-overlay">
                                <h5 className="card-title">SOFTWARE</h5>
                            </div>
                        </Link>
                    </div>
                    <div className="card text-white services-cards col-md-3">
                    <Link to='/digital-marketing'>
                        <img src={require('../assets/img/services/cardsImages/marketing.jpg')} className="card-img" alt="Software" />
                        <div className="card-img-overlay">
                            <h5 className="card-title">DIGITAL MARKETING</h5>
                        </div>
                        </Link>
                    </div>
                    <div className="card text-white services-cards col-md-3">
                    <Link to='/data-analysis'>
                        <img src={require('../assets/img/services/cardsImages/data_analysis.jpg')} className="card-img" alt="Software" />
                        <div className="card-img-overlay">
                            <h5 className="card-title">DATA ANALAYSIS</h5>
                        </div>
                    </Link>
                    </div>
                </div>
            </div>


            <div className='d-flex justify-content-center'><i class="fa-solid fa-chevron-down "></i></div>
        </div >
    )
}

export default Services