import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Header() {
    return (
        <div className='row w-100'>
            <p className='col-md-2 offset-md-2 mail-info'><i class="fa-regular fa-envelope m-1"></i>support@idol-soft.com</p>
            <Navbar className='navbar ms-1' collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Container>
                    <Navbar.Brand href="/"><img width={200} src={require('../assets/img/logo/uk-web.png')} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                        </Nav>
                        <Nav>
                            <Nav.Link href="/services">SERVICES</Nav.Link>
                            <Nav.Link href="/who-are-we">ABOUT US</Nav.Link>
                            <Nav.Link href="/contact">CONTACT</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar></div>
    )
}

export default Header