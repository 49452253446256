import React from 'react'
import { Link } from 'react-router-dom'

function NotFound() {
    return (
        <div className='my-4 justify-content-center'>
            <div className='d-flex justify-content-center'>
                <div className='ms-4 d-flex align-items-center'>
                    <div className='text-center mb-4'>
                        <h3 className='text-dark mb-4'>Page Not Found</h3>
                        <Link
                            className="btn btn-outline-dark"
                            to='/'
                        >
                            Go Home
                        </Link>
                    </div>
                </div>

            </div>
            <div className='d-flex justify-content-center'>
                <img src={require('../assets/img/notFound/404.jpg')} className='me-4 img-fluid d-flex ' /></div>
        </div>
    )
}

export default NotFound