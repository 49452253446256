import React from 'react'

function Address() {
    return (
        <section className="map-section">
            <div className="map-column">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11777.651550748767!2d19.2678751!3d42.4402304!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x22c44fdccc3af3a!2sOmladinskih%20Brigada!5e0!3m2!1str!2str!4v1667212953339!5m2!1str!2str" width="100%" height="700" allowfullscreen="" loading="lazy"></iframe>
            </div>
        </section>
    )
}

export default Address